import React, { useState, useEffect } from 'react';
import './home.css';
import { ReactComponent as ManageIcon } from '../../assets/upload.svg';
import { ReactComponent as AnnotateIcon } from '../../assets/edit.svg';
import { ReactComponent as OptimizeIcon } from '../../assets/world.svg';
import { ReactComponent as SecureIcon } from '../../assets/lock.svg';
import Screenshot from '../../assets/screenshot.png';

const API_URL = process.env.REACT_APP_API_URL;

const Home = () => {

  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, message } = e.target;

    try {
      const response = await fetch(`${API_URL}/mail/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name.value,
          email: email.value,
          message: message.value,
        }),
      });

      if (response.ok) {
        alert('Thank you! Your message has been sent.');
        e.target.reset();
      } else {
        alert('Oops! Something went wrong. Please try again later.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('An error occurred while sending your message.');
    }
  };

  useEffect(() => {
    const img = new Image();
    img.src = Screenshot;

    // Check if the image is already cached by the browser
    if (img.complete) {
      setIsImageLoaded(true);
    } else {
      setIsImageLoading(true); // Image needs to load
      img.onload = () => {
        setIsImageLoading(false);
        setIsImageLoaded(true);
      };
    }
  }, []);

  
  return (
    <div className="container">
      {/* Page title and subtitle */}
      <div className="title-section">
        <h1>Leverage the full educational value in surgical videos.</h1>
        <h3>The most comprehensive surgical visualization tool on the market</h3>
      </div>

      {/* Section with catchy title and screenshot */}
      <div className="annotation-tools-section">
        <div className="annotation-text">
          <h2>Access Multiple Annotation Tools</h2>
        </div>
        <div className="annotation-image">
          {/* Show image with or without blur based on loading */}
          <img
            src={Screenshot}
            alt="Tool Screenshot"
            className={`tool-screenshot ${isImageLoading ? 'blurred' : 'loaded'}`}
            style={{
              transition: 'opacity 0.05s ease-in-out', // Inline style to enforce transition
            }}
          />
        </div>
      </div>

      {/* Rest of the content remains the same */}
      <div className="content">
        <div className="feature">
          <div className="icon-container">
            <ManageIcon className="icon" />
          </div>
          <div className="text">Manage your surgical videos</div>
        </div>
        <div className="feature">
          <div className="icon-container">
            <AnnotateIcon className="icon" />
          </div>
          <div className="text">Annotate the content</div>
        </div>
        <div className="feature">
          <div className="icon-container">
            <OptimizeIcon className="icon" />
          </div>
          <div className="text">Optimized for teaching purposes</div>
        </div>
        <div className="feature">
          <div className="icon-container">
            <SecureIcon className="icon" />
          </div>
          <div className="text">Secure</div>
        </div>
      </div>

      {/* Contact form */}
      <div className="contact-section">
        <h2>Contact us to schedule a demo in your institution</h2>
        <form className="contact-form" onSubmit={handleSubmit}>
          <label>
            Name:
            <input type="text" name="name" placeholder="Your Name" required />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
            />
          </label>
          <label>
            Message:
            <textarea name="message" placeholder="Your Message" required />
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Home;