import React, { useEffect, useRef, useState, useMemo } from 'react';
import './videoplayer.css';
import {PulseLoader} from 'react-spinners';
import { ReactComponent as RestartIcon } from '../../assets/restart.svg';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import {
  handleLoadedData,
  handleTimeUpdate,
  handleTimelineUpdate,
  handleVideoEnd,
  Drawing,
  CanvasManager,
  VideoPlaybackHandler,
  changePlaybackSpeed,
  clickAnimation,
  toggleColorPalette,
  toggleDrawMode,
  handlePlay,
  handlePause,
  formatDuration,
  smoothScrollTo



} from './videoplayer_lib.js';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import Hls from 'hls.js'; // Import HLS.js library







// only supports safari for now
function VideoPlayer({videoLink, videoMetadata}) {

  // some states and refs
  const authHeader = useAuthHeader();
  const [canvasEdited, setCanvasEdited] = useState(false);
  const [drawMode, setDrawMode] = useState('');// pen,circle,arrow
  const [color, setColor] = useState('white');
  const [thickness, setThickness] = useState(2);
  const [loading, setLoading] = useState(true);
  const [preloading, setPreloading] = useState(true);
  const [canvasStatus, setCanvasStatus] = useState({});
  const [lastPausedTime, setLastPausedTime] = useState(0);
  const [isDrawing, setIsDrawing] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [isHlsSupported, setIsHlsSupported] = useState(true);
  const [isEditing, setIsEditing] = useState(true);
  const [isRetooling, setIsRetooling] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);
  const lastScrolledAnnotationTimeRef = useRef(null);
  const [playbackState, setPlaybackState] = useState('paused'); // 'playing', 'paused'
  const [activeTab, setActiveTab] = useState('procedure'); // Default to 'procedure'
  const [annotationTitle, setAnnotationTitle] = useState('');
  const [annotationCommentary, setAnnotationCommentary] = useState('');
  const [annotations, setAnnotations] = useState([]); // Add this line
  

  const playPauseInProgress = useRef(false);

  const videoRef = useRef(null);
  const hotCanvasRef = useRef(null);
  const coldCanvasRef = useRef(null);
  const startX = useRef(0);
  const startY = useRef(0);
  const isScrubbingRef = useRef(false);
  const restartIconRef = useRef(null);

  const videoId = videoMetadata.videoId;

  // initialize helper classes
  const drawingTool = useMemo(() => new Drawing(), []);
  const canvasManager = useMemo(() => new CanvasManager(), []);
  const videoPlaybackHandler = useMemo(() => new VideoPlaybackHandler(), []);


  

  // fns to manage canvasStatus state
  function deleteCanvasStatus  (time) {
    setCanvasStatus(prevStatus => {
      const newStatus = { ...prevStatus };
      delete newStatus[time];
      return newStatus;
    });
  };
  function updateCanvasStatus (time, status)  {
    setCanvasStatus(prevStatus => ({
      ...prevStatus,
      [time]: status
    }));
  };
  function resetStatus() {
    setCanvasStatus(prevStatus => {
      const newStatus = {};
      for (const key in prevStatus) {
        newStatus[key] = "U";
      }
      return newStatus;
    });
  }


  const handleColorCycle = () => {
    const colorOptions = ['#61dafb', 'green', 'yellow', 'red', 'white', 'black'];
    const currentIndex = colorOptions.indexOf(color);
    const nextIndex = (currentIndex + 1) % colorOptions.length;
    setColor(colorOptions[nextIndex]);
  };

  // Function to cycle through thickness levels
  const handleThicknessCycle = () => {
    const thicknessLevels = [2, 5, 8];
    const currentIndex = thicknessLevels.indexOf(thickness);
    const nextIndex = (currentIndex + 1) % thicknessLevels.length;
    setThickness(thicknessLevels[nextIndex]);
  };



  useEffect(() => {
    const video = videoRef.current;

    if (!video) return;

    let hls;



    // Handlers for native and HLS video events
    const handleWaiting = () => {
        //console.log('Buffering started');
        setIsBuffering(true);
    };

    const handlePlaying = () => {
        //console.log('Playback started');
        setLoading(false);
        setIsBuffering(false);
    };

    const handleLoadedMetadata = () => {
        //console.log('Metadata loaded, starting video playback');
        videoRef.current.currentTime = 0; 
        setLoading(false);
        setIsBuffering(false);
        //video.play();
    };

    const handleError = (event, data) => {
        console.error('HLS.js error:', data);
        if (data.type === Hls.ErrorTypes.NETWORK_ERROR) {
            setLoading(false);
            setVideoError(true);
            setIsBuffering(false);
        }
    };

    if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(videoLink);
        hls.attachMedia(video);

        hls.on(Hls.Events.ERROR, handleError);

        video.addEventListener('waiting', handleWaiting);
        video.addEventListener('playing', handlePlaying);
        video.addEventListener('loadedmetadata', handleLoadedMetadata);

        /*video.play().catch((error) => {
            console.error('Autoplay error:', error);
            setLoading(false);
            setVideoError(true);
        });*/

        return () => {
            hls.destroy();
            hls.off(Hls.Events.ERROR, handleError);
            video.removeEventListener('waiting', handleWaiting);
            video.removeEventListener('playing', handlePlaying);
            video.removeEventListener('loadedmetadata', handleLoadedMetadata);
        };
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = videoLink;

        video.addEventListener('waiting', handleWaiting);
        video.addEventListener('playing', handlePlaying);
        video.addEventListener('loadedmetadata', handleLoadedMetadata);

        video.onerror = () => {
            console.error('Native video error');
            setLoading(false);
            setVideoError(true);
            setIsBuffering(false);
        };

        /*video.play().catch((error) => {
            console.error('Autoplay error:', error);
            setLoading(false);
            setVideoError(true);
        });*/

        return () => {
            video.removeEventListener('waiting', handleWaiting);
            video.removeEventListener('playing', handlePlaying);
            video.removeEventListener('loadedmetadata', handleLoadedMetadata);
        };
    } else {
        console.error('HLS is not supported on this browser.');
        setLoading(false);
        setVideoError(true);
    }
}, [videoLink]);






  // hook for tracing and scrubbing (videoPlaybackHandler)
  useEffect(() => {
    
    const token = authHeader.split(' ')[1];

    // components to attach event listeners
    const currentTimeElem = document.querySelector(".current-time")
    const videoContainer = document.querySelector(".editor-container")
    const timelineContainer = document.querySelector(".timeline-container")
    const video = document.querySelector("video")

    if (!video || !currentTimeElem || !videoContainer || !timelineContainer) {
      return;
    }
    if (typeof window === 'undefined' || typeof document === 'undefined') {
      return;
    }

    // arguments for video playback handler
    const args = {
      videoRef: videoRef,
      videoLink: videoLink,
      videoContainer: videoContainer,
      coldCanvasRef: coldCanvasRef,
      setCanvasEdited: setCanvasEdited,
      setLastPausedTime: setLastPausedTime,
      setPlaybackState: setPlaybackState,
      lastPausedTime: lastPausedTime,
      canvasManager: canvasManager,
      canvasStatus: canvasStatus,
      updateCanvasStatus: updateCanvasStatus,
      currentTimeElem: currentTimeElem,
      timelineContainer: timelineContainer,
      isScrubbingRef: isScrubbingRef,
      setLoading: setLoading,
      hotCanvasRef: hotCanvasRef,
      document: document,
      setDrawMode: setDrawMode,
      resetStatus: resetStatus,
      token: token,
      videoId: videoId,
      setAnnotationCommentary: setAnnotationCommentary,
      setAnnotationTitle:setAnnotationTitle,
      setAnnotations: setAnnotations,
      setPreloading: setPreloading
      
    }

    const areAnyArgsNull = Object.values(args).some(value => value === null || value === undefined);

    if (areAnyArgsNull) {
      return;
    }

    // setting arguments
    videoPlaybackHandler.setArgs(args);

    // some helper functions
    const drawTracingPlay = () => {
      videoPlaybackHandler.drawTracingPlay();
    };

    const drawTracingPause = () => {
      videoPlaybackHandler.drawTracingPause();
    };



    const handleMouseMove = (e) => {
      // Reset all indicators first
      const indicators = timelineContainer.querySelectorAll('.timeline-indicator');
      indicators.forEach(indicator => {
        indicator.style.transform = 'none';
      });

      // Update preview
      if (timelineContainer.contains(e.target)) {
        const preview = timelineContainer.querySelector('.timeline-preview');
        const hoveredIndicator = e.target.closest('.timeline-indicator');
        
        if (hoveredIndicator) {
          // Apply hover animation to the hovered indicator
          hoveredIndicator.style.transform = 'translateY(-3px) scale(1.2)';
          
          const timeId = hoveredIndicator.id.split('-')[1];
          preview.textContent = formatDuration(parseFloat(timeId));
          preview.classList.add('indicator-preview');
          preview.style.left = hoveredIndicator.style.left;
          preview.style.display = 'block';
        } else {
          const rect = timelineContainer.getBoundingClientRect();
          const percent = Math.min(Math.max(0, e.clientX - rect.left), rect.width) / rect.width;
          if (percent === 0) {
            preview.style.display = 'none';
            return;
          }
          const previewTime = percent * videoRef.current.duration;
          preview.textContent = formatDuration(previewTime);
          preview.classList.remove('indicator-preview');
          preview.style.left = `${e.clientX - rect.left}px`;
          preview.style.display = 'block';
        }
      }

      // Handle scrubbing if in progress
      if (isScrubbingRef.current) {
        handleTimelineUpdate(e, timelineContainer, isScrubbingRef.current);
      }
    };

    const handleMouseLeave = () => {
      const preview = timelineContainer.querySelector('.timeline-preview');
      if (preview) {
        preview.style.display = 'none';
      }
    };

    const handleMouseUp = (e) => {
      if (isScrubbingRef.current) {
        videoPlaybackHandler.toggleScrubbing(e, loading);
      }
    };

    const handleToggleScrubbing = (e) => {
      videoPlaybackHandler.toggleScrubbing(e, loading);
    };

    const handleVisibilityChange = (e) => {
    if (!videoRef.current) return
      
      if (!videoRef.current.paused){
         //videoRef.current.pause();
         canvasManager.togglePlay({
          playbackState,
          setPlaybackState,
          playPauseInProgress,
        });
      }

      if (document.visibilityState === 'visible') {
        videoPlaybackHandler.handleVisibilityChange(true);
        handleTimelineUpdate(e, timelineContainer, isScrubbingRef.current)

      } else {
        videoPlaybackHandler.handleVisibilityChange(false);
      }
    };
    const pauseWhenNoVisibility = () => {
      if (!videoRef.current) return

      if (document.visibilityState !== 'visible') {
        videoRef.current.pause();
        setPlaybackState("paused")
        if (!videoRef.current.paused){
          canvasManager.togglePlay({
            playbackState,
            setPlaybackState,
            playPauseInProgress,
          });

        }
        
      }
    };

    const handleTimelinePreview = (e) => {
      const rect = timelineContainer.getBoundingClientRect();
      const previewPosition = Math.min(Math.max(0, e.x - rect.x), rect.width) / rect.width;
      
      // Set the preview position CSS variable
      timelineContainer.style.setProperty("--preview-position", previewPosition);
    
      // If we're scrubbing, also update the progress position
      if (isScrubbingRef.current) {
        e.preventDefault();
        timelineContainer.style.setProperty("--progress-position", previewPosition);
      }
    };


    // Add event listeners
timelineContainer.addEventListener("mousemove", handleMouseMove);
timelineContainer.addEventListener("mouseenter", handleTimelinePreview);
timelineContainer.addEventListener("mouseleave", () => {
  timelineContainer.style.setProperty("--preview-position", "0");
});


    // add event listeners
    video.addEventListener("timeupdate", pauseWhenNoVisibility);
    document.addEventListener('visibilitychange', e => handleVisibilityChange(e));    
    window.addEventListener('resize', () => videoPlaybackHandler.setCanvasSize());
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("webkitfullscreenchange", () => videoPlaybackHandler.handleFullScreenChange());
    document.addEventListener("fullscreenchange", () => videoPlaybackHandler.handleFullScreenChange());
    video.addEventListener('loadedmetadata', () => videoPlaybackHandler.initializeTimelineIndicators());
    video.addEventListener('play', drawTracingPlay);
    video.addEventListener('pause', drawTracingPause);
    video.addEventListener('loadedmetadata',() =>  videoPlaybackHandler.setCanvasSize());
    timelineContainer.addEventListener("mousedown", handleToggleScrubbing);
    timelineContainer.addEventListener("mousemove", handleMouseMove);
    // remove event listeners
    return () => {

      timelineContainer.removeEventListener("mousemove", handleMouseMove);
      timelineContainer.removeEventListener("mouseenter", handleTimelinePreview);
      timelineContainer.removeEventListener("mouseleave", () => {
        timelineContainer.style.setProperty("--preview-position", "0");
      });
      
      





      video.removeEventListener("timeupdate", pauseWhenNoVisibility);
      document.removeEventListener('visibilitychange', e => handleVisibilityChange(e));
      window.removeEventListener('resize', () => videoPlaybackHandler.setCanvasSize());
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("webkitfullscreenchange", () => videoPlaybackHandler.handleFullScreenChange());
      document.removeEventListener("fullscreenchange", () => videoPlaybackHandler.handleFullScreenChange());
      video.removeEventListener('loadedmetadata',() =>  videoPlaybackHandler.setCanvasSize());
      video.removeEventListener('play', drawTracingPlay);
      video.removeEventListener('pause', drawTracingPause);
      video.removeEventListener('loadedmetadata', () => videoPlaybackHandler.initializeTimelineIndicators());
      timelineContainer.removeEventListener("mousemove", handleMouseMove);
      timelineContainer.removeEventListener("mousedown", handleToggleScrubbing);
    };
  },  [playbackState, playPauseInProgress, videoId,authHeader,drawMode,canvasEdited, color, thickness, videoLink, canvasStatus, loading, lastPausedTime, canvasManager, videoPlaybackHandler]);

  // hook for video controls (canvasManager)
  useEffect(() => {

    const token = authHeader.split(' ')[1];

    // components to attach event listeners
    const components = {
      videoContainer: document.querySelector(".editor-container"),
      videoEditContainer: document.querySelector(".edit-tools-container"),
      speedBtn: document.querySelector(".speed-btn"),
      currentTimeElem: document.querySelector(".current-time"),
      timelineContainer: document.querySelector(".timeline-container"),
      video: document.querySelector("video"),
      playPauseBtn: document.querySelector(".play-pause-btn"),
      fullScreenBtn: document.querySelector(".full-screen-btn"),
      editBtn: document.querySelector(".edit-btn"),
      trashBtn: document.querySelector(".trash-btn"),
      saveBtn: document.querySelector(".save-btn"),

      colorBtn: document.querySelector(".color-btn"),
      totalTimeElem: document.querySelector(".total-time"),

    };

    const saveBtn2= document.querySelector(".save-btn-2");

    if (Object.values(components).some(value => value === null || value === undefined)) {
      return;
    }

    // canvas manager arguments
    const canvasManagerArgs = {
      coldCanvasRef: coldCanvasRef,
      setCanvasEdited: setCanvasEdited,
      setDrawMode: setDrawMode,
      loading: loading,
      lastPausedTime: lastPausedTime,
      videoRef: videoRef,
      videoLink: videoLink,
      document: document,
      canvasStatus: canvasStatus,
      canvasEdited: canvasEdited,
      resetStatus: resetStatus,
      updateCanvasStatus: updateCanvasStatus,
      deleteCanvasStatus: deleteCanvasStatus,
      setCanvasStatus: setCanvasStatus,
      videoContainer: components.videoContainer,
      videoEditContainer: components.videoEditContainer,
      setLastPausedTime: setLastPausedTime,
      setPlaybackState: setPlaybackState,
      currentTimeElem: components.currentTimeElem,
      timelineContainer: components.timelineContainer,
      speedBtn: components.speedBtn,
      restartIconRef: restartIconRef,
      token: token,
      videoId: videoId,
      drawMode: drawMode,
      annotationCommentary: annotationCommentary,
      annotationTitle: annotationTitle,
      setAnnotationCommentary: setAnnotationCommentary,
      setAnnotationTitle: setAnnotationTitle,
      setAnnotations: setAnnotations,
      annotations: annotations
    }

    const areAnyArgsNull = Object.values(canvasManagerArgs).some(value => value === null || value === undefined);

    if (areAnyArgsNull) {
      return;
    }

    // setting arguments
    canvasManager.setArgs(canvasManagerArgs);

    // stable references to avoid latency
    const handleFullscreen = (e) => {
      e.preventDefault();
      e.stopPropagation();
      canvasManager.toggleFullScreenMode()
    };
    /*const handleEditContainer = () => {
      canvasManager.toggleVideoEditContainer();
    };*/
    const handleTogglePlay = () => {
      canvasManager.togglePlay({
        playbackState,
        setPlaybackState,
        playPauseInProgress,
      });
    };
    const handleKeyDown = (e) => {
      canvasManager.keyDown(e,playbackState, setPlaybackState, playPauseInProgress);
    };
    const handleSpeedChange = () => {
      changePlaybackSpeed(videoRef,components.speedBtn);
    };
    const handleEditToggle = () => {
      setIsEditing(prev => !prev);
    };

    
      
    // event listeners
    components.editBtn.addEventListener('click', handleEditToggle);
    restartIconRef.current.addEventListener('click', handleTogglePlay); // this event listener is not removed because its a ref
    components.video.addEventListener('ended', () => canvasManager.toggleRestartIcon());
    components.speedBtn.addEventListener("click", handleSpeedChange);
    components.fullScreenBtn.addEventListener("click", handleFullscreen);
    components.video.addEventListener("dblclick", handleFullscreen);
    components.playPauseBtn.addEventListener("click", handleTogglePlay);
    //components.editBtn.addEventListener("click", handleEditContainer);
    components.trashBtn.addEventListener("click", () => canvasManager.toggleDeleteCanvas());
    components.saveBtn.addEventListener("click", () => canvasManager.saveCanvas() );
    components.saveBtn.addEventListener("click", () => clickAnimation(components.saveBtn));
    if (saveBtn2){
      saveBtn2.addEventListener("click", () => canvasManager.saveCanvas() );
      saveBtn2.addEventListener("click", () => clickAnimation(saveBtn2));
    }
    components.trashBtn.addEventListener("click", () => clickAnimation(components.trashBtn));
    components.colorBtn.addEventListener("click", () => clickAnimation(components.colorBtn));
    document.addEventListener('keydown', handleKeyDown);
    components.video.addEventListener("loadeddata", () => handleLoadedData(videoRef, components.totalTimeElem));
    components.video.addEventListener("timeupdate", () => handleTimeUpdate(videoRef, components.currentTimeElem, components.timelineContainer));
    components.video.addEventListener("click", handleTogglePlay);
    components.video.addEventListener("play", () => handlePlay(videoRef, components.videoContainer, lastPausedTime, canvasManager));
    components.video.addEventListener("pause", () =>  handlePause(videoRef, components.videoContainer, setLastPausedTime));
    components.video.addEventListener("ended", () => handleVideoEnd(videoRef, lastPausedTime, setPlaybackState));
    components.video.addEventListener("ended", resetStatus);

    // removing the event listeners
    return () => {
      components.editBtn.removeEventListener('click', handleEditToggle);
      components.video.removeEventListener('ended', () => canvasManager.toggleRestartIcon());
      document.removeEventListener('keydown', handleKeyDown);
      components.speedBtn.removeEventListener("click", handleSpeedChange);
      components.fullScreenBtn.removeEventListener("click",handleFullscreen);
      components.video.removeEventListener("dblclick", handleFullscreen);
      components.playPauseBtn.removeEventListener("click", handleTogglePlay);
      //components.editBtn.removeEventListener("click", handleEditContainer);
      components.trashBtn.removeEventListener("click", () => canvasManager.toggleDeleteCanvas());
      components.saveBtn.removeEventListener("click", () => canvasManager.saveCanvas());
      components.saveBtn.removeEventListener("click", () => clickAnimation(components.saveBtn));
      if (saveBtn2){
        saveBtn2.removeEventListener("click", () => canvasManager.saveCanvas());
        saveBtn2.removeEventListener("click", () => clickAnimation(saveBtn2));
      }
      components.trashBtn.removeEventListener("click", () => clickAnimation(components.trashBtn));
      components.colorBtn.removeEventListener("click", () => clickAnimation(components.colorBtn));
      components.video.removeEventListener("loadeddata", () => handleLoadedData(videoRef, components.totalTimeElem));
      components.video.removeEventListener("timeupdate", () => handleTimeUpdate(videoRef, components.currentTimeElem, components.timelineContainer));
      components.video.removeEventListener("click", handleTogglePlay);
      components.video.removeEventListener("play", () => handlePlay(videoRef, components.videoContainer, lastPausedTime, canvasManager));
      components.video.removeEventListener("pause", () => handlePause(videoRef, components.videoContainer, setLastPausedTime));
      components.video.removeEventListener("ended", () => handleVideoEnd(videoRef, lastPausedTime, setPlaybackState));
      components.video.removeEventListener("ended", resetStatus);
    }
  },[annotationTitle, annotations, annotationCommentary,videoId, authHeader, videoLink, canvasEdited, color, thickness, drawMode, canvasStatus, loading, lastPausedTime, canvasManager, videoPlaybackHandler, playPauseInProgress, playbackState]);


  // hook for drawing tool (drawingTool)
  useEffect(() => {
    // components to attach event listeners
    const hotCanvasObj = hotCanvasRef.current;
    const coldCanvasObj = coldCanvasRef.current;

    if (!hotCanvasObj || !coldCanvasObj){
      return;
    }

    // arguments for drawing tool
    const args = {
      hotCanvas: hotCanvasObj,
      coldCanvas: coldCanvasObj,
      ctx_cold: coldCanvasObj.getContext('2d'),
      ctx_hot: hotCanvasObj.getContext('2d'),
      startX: startX,
      startY: startY,
      color: color,
      thickness: thickness,
      drawMode: drawMode
    };

    const areAnyArgsNull = Object.values(args).some(value => value === null || value === undefined);

    if (areAnyArgsNull) {
      return;
    }

    // setting arguments
    drawingTool.updateDrawConfig(args);


    // helpers to select the correct draw function
    const handleStartDrawing = (e) => {
      drawingTool.drawFunctionMap[drawMode].start(e);
      setIsDrawing(true);
    };
    const handleDraw = (e) => {
      if (!isDrawing) return;
      drawingTool.drawFunctionMap[drawMode].draw(e);
      setCanvasEdited(true);
    };
    const handleStopDrawing = () => {
      setIsDrawing(false);
      drawingTool.drawFunctionMap[drawMode].stop();
    };

    
    // event listeners
    if (drawMode !== '') {
      hotCanvasObj.addEventListener('mousedown', handleStartDrawing);
      hotCanvasObj.addEventListener('mousemove', handleDraw);
      hotCanvasObj.addEventListener('mouseup', handleStopDrawing);
      hotCanvasObj.addEventListener('mouseout', handleStopDrawing);
    }

    // removing the event listeners
    return () => {
      hotCanvasObj.removeEventListener('mousedown', handleStartDrawing);
      hotCanvasObj.removeEventListener('mousemove', handleDraw);
      hotCanvasObj.removeEventListener('mouseup', handleStopDrawing);
      hotCanvasObj.removeEventListener('mouseout', handleStopDrawing);
    };
  }, [drawMode, color, thickness, isDrawing, drawingTool]);

  // hook to remove default video controls
  useEffect(() => {

    // some helper functions
    const videoContainer = document.querySelector(".editor-container");
    if (!videoContainer) return;
    const preventDefault = (e) => e.preventDefault();
    const preventLeftClick = (e) => {
      if (e.button === 0) {
        e.preventDefault();
      }
    };

    // adding event listeners
    videoContainer.addEventListener('contextmenu', preventDefault);
    videoContainer.addEventListener('mousedown', preventLeftClick);
    videoContainer.addEventListener('dragstart', preventDefault);
    videoContainer.addEventListener('selectstart', preventDefault);

    // removing event listeners
    return () => {
      videoContainer.removeEventListener('contextmenu', preventDefault);
      videoContainer.removeEventListener('mousedown', preventLeftClick);
      videoContainer.removeEventListener('dragstart', preventDefault);
      videoContainer.removeEventListener('selectstart', preventDefault);
    };
  }, []);




  
// Hook for indicators
useEffect(() => {
  const token = authHeader.split(' ')[1];

  // Components to attach event listeners
  const currentTimeElem = document.querySelector('.current-time');
  const videoContainer = document.querySelector('.editor-container');
  const timelineContainer = document.querySelector('.timeline-container');
  const video = document.querySelector('video');

  if (!video || !currentTimeElem || !videoContainer || !timelineContainer) {
    return;
  }
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return;
  }

  // Arguments for video playback handler
  const args = {
    videoRef: videoRef,
    videoLink: videoLink,
    videoContainer: videoContainer,
    coldCanvasRef: coldCanvasRef,
    setCanvasEdited: setCanvasEdited,
    setLastPausedTime: setLastPausedTime,
    lastPausedTime: lastPausedTime,
    canvasManager: canvasManager,
    canvasStatus: canvasStatus,
    updateCanvasStatus: updateCanvasStatus,
    currentTimeElem: currentTimeElem,
    timelineContainer: timelineContainer,
    isScrubbingRef: isScrubbingRef,
    setLoading: setLoading,
    hotCanvasRef: hotCanvasRef,
    document: document,
    setDrawMode: setDrawMode,
    resetStatus: resetStatus,
    setPlaybackState: setPlaybackState,
    token: token,
    videoId: videoId,
    loading: loading, // Include loading state
    setAnnotationTitle: setAnnotationTitle,
    setAnnotationCommentary: setAnnotationCommentary,
    setAnnotations: setAnnotations,
    setPreloading: setPreloading
  };

  const areAnyArgsNull = Object.values(args).some(
    (value) => value === null || value === undefined
  );

  if (areAnyArgsNull) {
    return;
  }

  // Setting arguments
  videoPlaybackHandler.setArgs(args);

  // Set up indicator event listeners
  videoPlaybackHandler.setupIndicatorEventListeners();

  // Function to remove glowing indicators and cards
  const removeGlowingIndicators = () => {
    const video = document.querySelector('video');
    if (!video) return;

    const currentTime = video.currentTime.toFixed(1);
    const escapedCurrTime = currentTime.replace('.', '\\.');

    // Find the currently active indicator and annotation card
    const currentIndicator = document.querySelector(`#indicator-${escapedCurrTime}`);
    const currentCard = document.querySelector(`#annotation-card-${escapedCurrTime}`);

    // Select all glowing indicators and annotation cards
    const glowingIndicators = document.querySelectorAll('.timeline-indicator.glowing');
    const glowingCards = document.querySelectorAll('.annotation-card.glowing');

    // Remove the glowing class from all indicators except the current one
    glowingIndicators.forEach((indicator) => {
      if (indicator !== currentIndicator) {
        indicator.classList.remove('glowing');
      }
    });

    // Remove the glowing class from all annotation cards except the current one
    glowingCards.forEach((card) => {
      if (card !== currentCard) {
        card.classList.remove('glowing');
      }
    });
  };

  // Debounce function
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }


// Function to handle time updates and scroll annotations
const handleTimeUpdate = debounce(() => {
  const video = document.querySelector('video');
  if (!video) return;

  const currentTime = video.currentTime;

  // Get all annotations and their times
  const annotationCards = Array.from(document.querySelectorAll('.annotation-card'));
  const annotationTimes = annotationCards.map((card) => ({
    time: parseFloat(card.getAttribute('data-time')),
    card: card
  }));

  // Find the last annotation that occurs at or before the current time
  const currentAnnotation = annotationTimes
    .filter((annotation) => annotation.time <= currentTime)
    .sort((a, b) => b.time - a.time)[0]; // Get the latest annotation

  if (currentAnnotation) {
    const { time, card } = currentAnnotation;

    // Check if this annotation is different from the last scrolled annotation
    if (lastScrolledAnnotationTimeRef.current !== time) {
      lastScrolledAnnotationTimeRef.current = time; // Update the last scrolled annotation time

      // Scroll the annotation card into view
      const annotationList = document.querySelector('.annotation-list');
      if (annotationList) {
        const offset = 20; // Adjust the offset as needed
        smoothScrollTo(annotationList, card, 1000, offset);
      }
    }
  }
}, 200); // Adjust debounce delay as needed


  // Attach event listeners
  video.addEventListener('timeupdate', handleTimeUpdate);
  video.addEventListener('timeupdate', removeGlowingIndicators);

  // Cleanup on unmount
  return () => {
    videoPlaybackHandler.cleanupIndicatorEventListeners();
    video.removeEventListener('timeupdate', handleTimeUpdate);
    video.removeEventListener('timeupdate', removeGlowingIndicators);

  };
}, [
  videoPlaybackHandler,
  videoRef,
  canvasStatus,
  loading,
  authHeader, canvasManager, lastPausedTime, videoId, videoLink
]);





  /*if ( videoError) {
    return (
      <div className="loading-message-container">
        <div className="surgeon-icons">
          <span role="img" aria-label="surgical-mask" className="bacteria">🦠</span>
          <span role="img" aria-label="syringe" className="bacteria">🦠</span>
          <span role="img" aria-label="hospital" className="bacteria">🦠</span>
        </div>
        <p className="loading-message-text">
          The procedure is still underway! Please scrub in and check back soon for the full operation.
        </p>
        
      </div>
    );
  }*/
  
  return (
    <div className="main-container">
      <div className="editor-container paused" data-volume-level="high">
        {/* Edit Tools Sidebar */}
        <div className="edit-tools-container">

        <button
            className={`info-btn gen-btn ${showInfo ? 'active' : ''}`}
            onClick={() => setShowInfo(!showInfo)}
          >
            <InfoIcon/>
          </button>
          {/* Edit Toggle Button */}
          <button className={`edit-btn gen-btn ${isEditing ? 'active' : ''}`}>
            <svg className="pencil" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M8.56078 20.2501L20.5608 8.25011L15.7501 3.43945L3.75012 15.4395V20.2501H8.56078ZM15.7501 5.56077L18.4395 8.25011L16.5001 10.1895L13.8108 7.50013L15.7501 5.56077ZM12.7501 8.56079L15.4395 11.2501L7.93946 18.7501H5.25012V16.0608L12.7501 8.56079Z"
              />
            </svg>
          </button>
          {/* Edit Tools (Always rendered, visibility controlled by CSS) */}



          <div className={`edits ${isEditing ? 'visible' : ''}`}>
            <button className={`draw-btn ${drawMode === 'pen' ? 'active' : ''}`}
              onClick={() => toggleDrawMode('pen', videoRef, setDrawMode, setPlaybackState)}
            >
              <svg className="draw" viewBox="0 0 24 24">
                <path
                  fill="none"
                  stroke="#000000"
                  d="M3,13.5793719 C9.98914658,5.83454059 14.2442185,2.78592627 15.7652158,4.43352892 C18.0467117,6.90493289 7.55581053,16.1455344 9.47834357,17.954063 C11.4008766,19.7625917 16.9959382,11.5719148 19.0578414,12.4109285 C21.1197445,13.2499421 16.1152903,18.1722847 17.4055985,18.9997829 C18.2658039,19.5514483 19.3191667,19.0606734 20.5656867,17.527458"
                ></path>
              </svg>
            </button>
            
            <button className={`arrow-btn ${drawMode === 'arrow' ? 'active' : ''}`}
              onClick={() => toggleDrawMode('arrow', videoRef, setDrawMode, setPlaybackState)}
            >
              <svg className="arrow" viewBox="0 0 24 24">
                <path
                  fill="none"
                  stroke="#000000"
                  d="M4 12H20M4 12L8 8M4 12L8 16"
                ></path>
              </svg>
            </button>
            <button className={`circle-btn ${drawMode === 'circle' ? 'active' : ''}`}
              onClick={() => toggleDrawMode('circle', videoRef, setDrawMode, setPlaybackState)}
            >
              <svg className="circle" viewBox="0 0 24 24">
                <path
                  fill="none"
                  stroke="#000000"
                  d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                ></path>
              </svg>
            </button>
          
          </div>

          
        {/* Color Picker */}
        <button
          className="color-btn"
          onClick={handleColorCycle}
          style={{ backgroundColor: color }}
          aria-label="Color Picker"
        >
          <svg className="color-icon" viewBox="0 0 24 24">
            <path
              fill="none"
              stroke="#000000"
              d="M15.5 8.5H15.51M10.5 7.5H10.51M7.5 11.5H7.51M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 13.6569 19.6569 15 18 15H17.4C17.0284 15 16.8426 15 16.6871 15.0246C15.8313 15.1602 15.1602 15.8313 15.0246 16.6871C15 16.8426 15 17.0284 15 17.4V18C15 19.6569 13.6569 21 12 21Z"
            ></path>
          </svg>
        </button>

        <button
          className="thickness-btn"
          onClick={handleThicknessCycle}
          aria-label="Thickness Picker"
        >
          <div
            className="thickness-indicator"
            style={{ 
              height: `${thickness}px`, 
              backgroundColor: color,
              borderRadius: thickness === 12 ? '6px' : '4px',  // Make the third option more rounded
              width: thickness === 12 ? '70%' : '60%'          // Slightly increase the width for the third option
            }}
          ></div>
        </button>
            <button className="save-btn gen-btn">
              <svg className="save" viewBox="0 0 24 24">
                <path
                  fill="none"
                  stroke="#000000"
                  d="M15 20V15H9V20M18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4H14.1716C14.702 4 15.2107 4.21071 15.5858 4.58579L19.4142 8.41421C19.7893 8.78929 20 9.29799 20 9.82843V18C20 19.1046 19.1046 20 18 20Z"
                ></path>
              </svg>
            </button>
            <button className="trash-btn gen-btn">
              <svg className="trash" viewBox="0 0 24 24">
                <path
                  fill="none"
                  stroke="#000000"
                  d="M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M18 6V16.2C18 17.8802 18 18.7202 17.673 19.362C17.3854 19.9265 16.9265 20.3854 16.362 20.673C15.7202 21 14.8802 21 13.2 21H10.8C9.11984 21 8.27976 21 7.63803 20.673C7.07354 20.3854 6.6146 19.9265 6.32698 19.362C6 18.7202 6 17.8802 6 16.2V6M14 10V17M10 10V17"
                ></path>
              </svg>
            </button>
       
       
       

        </div>
        {showInfo && (
  <div className="info-card">
    <button
      className="close-info-btn"
      onClick={() => setShowInfo(false)}
      aria-label="Close Info Card"
    >
      &times;
    </button>
    <h1 className="info-title">{videoMetadata.videoTitle}</h1>
    <p className="info-description">{videoMetadata.videoDescription}</p>
    <hr className="info-separator" />
    <p>
      <strong>Surgeon(s):</strong> <span className="info-data">{videoMetadata.operatedBy}</span>
    </p>
    <p>
      <strong>Gender:</strong> <span className="info-data">{videoMetadata.patientGender}</span>
    </p>
    <p>
      <strong>Speciality:</strong> <span className="info-data">{videoMetadata.surgerySpeciality}</span>
    </p>
    <p>
      <strong>Procedure:</strong> <span className="info-data">{videoMetadata.surgeryType}</span>
    </p>
    <p>
      <strong>Upload Date:</strong>
      {videoMetadata.uploadDate && (
        <span className="info-date">
          {(() => {
            const date = new Date(videoMetadata.uploadDate);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return ` ${year}/${month}/${day}`;
          })()}
        </span>
      )}
    </p>
  </div>
)}


      {/* Video Container */}      {/*onLoadedMetadata={() => setPreloading(false)} */}
      <div className="video-wrapper">

        

        {isHlsSupported ? (
          <video ref={videoRef} autoPlay={false}>
            Your browser does not support the video tag.
          </video>
        ) : (
          <p>Your browser does not support HLS streaming.</p>
        )}

                {/* Canvases */}
        <canvas
          ref={coldCanvasRef}
          id="coldCanvas"
        ></canvas>
        <canvas
          ref={hotCanvasRef}
          id="hotCanvas"
        ></canvas>

            {/* Throttling Spinner */}
  {isBuffering && (
    <div className="throttling-spinner">
      <div className="spinner"></div>
    </div>
  )}
        {/* Video Controls */}

        <div className="video-controls-container">
          <div className="timeline-container">
            <div className="timeline">
              <div className="thumb-indicator"></div>
            </div>
          </div>
          <div className="controls">
            <button className="play-pause-btn">
              <svg className="play-icon" viewBox="0 0 24 24">
                <path fill="currentColor" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
              </svg>
              <svg className="pause-icon" viewBox="0 0 24 24">
                <path fill="currentColor" d="M14,19H18V5H14M6,19H10V5H6V19Z" />
              </svg>
            </button>
            <div className="duration-container">
              <div className="current-time">0:00</div>/<div className="total-time"></div>
            </div>
            <button className="speed-btn wide-btn">1x</button>
            <button className="full-screen-btn">
              <svg className="open" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"
                />
              </svg>
              <svg className="close" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"
                />
              </svg>
            </button>
          </div>
        </div>
        
        <div className="loader-vp">
          {loading && <PulseLoader color="#ffffff" loading={loading} size={15} />}
        </div>


        {/* Restart Icon */}
        <div className="restart-icon-container" ref={restartIconRef}>
          <RestartIcon className="restart-icon" />
        </div>



      {preloading && (
        <div className="video-preloader" />
      )}



      </div>






    </div>




    <div className="indicator-scroll-container">
  {preloading && <div className="video-preloader" />}

  {/* Annotation List */}
  <div className={`annotation-list ${drawMode !== '' ? 'hidden' : ''}`}>
    {/* No Annotations Message */}
    <p className="no-annotations-message">There are no annotations yet.</p>

    {/* Render Annotations */}
    {annotations && annotations.map((annotation) => (
      <div key={annotation.annotationId} className="annotation-card">
        <button className="annotation-delete-button" onClick={() => {/* Handle delete */}}>
          &times;
        </button>
        <div className="annotation-time">
          {formatDuration(parseFloat(annotation.time))}
        </div>
        <div className="annotation-title">{annotation.title}</div>
        <div className="annotation-commentary">{annotation.description}</div>
      </div>
    ))}
  </div>

  {/* Annotation Form (Integrated Within Container) */}
  {drawMode !== '' && (
    <div className="annotation-form-container">
      <div className="annotation-form">

        {/* Display lastPausedTime */}
        <div className="paused-time">
          <strong>Time:</strong> {formatDuration(parseFloat(lastPausedTime))}
        </div>

        {/* Annotation Form */}
        <form onSubmit={(e) => { e.preventDefault(); /* Handle form submission */ }}>
          <div className="form-group">
            <label htmlFor="annotation-title">
              <i className="fas fa-heading"></i> Annotation Title:
            </label>
            <input
              type="text"
              id="annotation-title"
              value={annotationTitle}
              onChange={(e) => setAnnotationTitle(e.target.value)}
              placeholder=" "
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="annotation-commentary">
              <i className="fas fa-comment-dots"></i> Commentary:
            </label>
            <textarea
              id="annotation-commentary"
              value={annotationCommentary}
              onChange={(e) => setAnnotationCommentary(e.target.value)}
              placeholder=" "
              required
            />
          </div>
        </form>

        <button className="save-btn-2 gen-btn">
              <svg className="save" viewBox="0 0 24 24">
                <path
                  fill="none"
                  stroke="#000000"
                  d="M15 20V15H9V20M18 20H6C4.89543 20 4 19.1046 4 18V6C4 4.89543 4.89543 4 6 4H14.1716C14.702 4 15.2107 4.21071 15.5858 4.58579L19.4142 8.41421C19.7893 8.78929 20 9.29799 20 9.82843V18C20 19.1046 19.1046 20 18 20Z"
                ></path>
              </svg>
            </button>
      </div>
    </div>
  )}
</div>


  </div>

  );
}
export default VideoPlayer;

